import React from 'react';
import { LiveChat } from '@gr/livechat';

const LICENCE_ID = '1071097';

export default function LivechatContainer() {
    const [isOpen, setIsOpen] = React.useState(false);

    const openChat = React.useCallback(() => {
        setIsOpen(true);
    }, []);

    const hideChat = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    const btnOpenLiveChat = document.querySelector('.start-chat');

    if (btnOpenLiveChat) {
        btnOpenLiveChat.addEventListener('click', () => {
            openChat();
        });
    }

    return (
        <LiveChat isOpen={isOpen} onClose={hideChat} buttonVariant={'bubble'} statisticsEnabled={false} licenceId={LICENCE_ID} language={window.grLiveChatData.lang} buttonText={window.grLiveChatData.buttonText}/>
    );
}
